import G6 from '@antv/g6';
import insertCss from 'insert-css';
import store from '@/store';
import { downloadData } from '@/utils/reuse';
import {
  addAsGoal, addChildrens, editNodeDetail, saveGraphLocalAndLive, removeNode, resetNode,
} from './questNodeContextOperations';

// define the CSS with the id of your menu

// We use 'insert-css' to insert custom styles
// It is recommended to add the style to your own style sheet files
// If you want to copy the code directly, please remember to install the npm package 'insert-css
insertCss(`
#contextMenu {
  position: absolute;
  list-style-type: none;
  padding: 10px 8px;
  left: -150px;
  background-color: #333;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  font-size: 12px;
  color: #545454;
}
#contextMenu li {
  cursor: pointer;
  list-style-type:none;
  list-style: none;
  margin-left: 0px;
}
#contextMenu li:hover {
  color: #aaa;
}

.g6-component-contextmenu {
    border: 1px solid #444;
    border-radius: 4px;
    font-size: 12px;
    color: #fff;
    background-color: #2f2f2f;
    padding: 5px 0;
    width: auto;
}

.g6-component-contextmenu ul {
    padding: 0;
    margin: 0;
}

.g6-component-contextmenu li {
    list-style-type: none;
    padding: 10px 16px;
    margin: 0;
    background-color: #2f2f2f;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
    width: 100%; /* make sure it covers full width */
    border-bottom: 1px solid #444; /* separates each list item with a thin line */
}

.g6-component-contextmenu li:last-child {
    border-bottom: none; /* removes the bottom border from the last list item */
}

.g6-component-contextmenu li:hover {
    background-color: #3f3f3f;
}



`);

/**
 * Animation
 */

G6.registerNode('background-animate', {
  afterDraw(cfg, group) {
    const r = cfg.size / 2;
    const back1 = group.addShape('circle', {
      zIndex: -3,
      attrs: {
        x: 0,
        y: 0,
        r,
        fill: cfg.color,
        opacity: 0.6,
      },
    });
    const back2 = group.addShape('circle', {
      zIndex: -2,
      attrs: {
        x: 0,
        y: 0,
        r,
        fill: cfg.color,
        opacity: 0.6,
      },
    });
    const back3 = group.addShape('circle', {
      zIndex: -1,
      attrs: {
        x: 0,
        y: 0,
        r,
        fill: cfg.color,
        opacity: 0.6,
      },
    });
    group.sort(); // 排序，根据 zIndex 排序
    back1.animate({ // 逐渐放大，并消失
      r: r + 10,
      opacity: 0.1,
      repeat: true, // 循环
    }, 3000, 'easeCubic', null, 0); // 无延迟
    back2.animate({ // 逐渐放大，并消失
      r: r + 10,
      opacity: 0.1,
      repeat: true, // 循环
    }, 3000, 'easeCubic', null, 1000); // 1 秒延迟
    back3.animate({ // 逐渐放大，并消失
      r: r + 10,
      opacity: 0.1,
      repeat: true, // 循环
    }, 3000, 'easeCubic', null, 2000); // 2 秒延迟
  },
}, 'circle');

/** ************************************************ */

// import graphReactor from '@/common/core/Graph/graphReactor';
// plugins
const minimap = new G6.Minimap({
  size: [150, 150],
  className: 'minimap',
  type: 'delegate',
  delegateStyle: {
    fill: '#ffffff', // Set the fill color to white
    stroke: '#000000', // Set the stroke color (border color) if needed
    lineWidth: 1, // Set the border width if needed
  },
});
// highlight plugins
const tooltip = new G6.Tooltip({
  offsetX: 10,
  offsetY: 10,
  fixToNode: [1, 0.5],
  // the types of items that allow the tooltip show up
  // 允许出现 tooltip 的 item 类型
  itemTypes: ['node', 'edge'],
  // custom the tooltip's content
  // 自定义 tooltip 内容
  getContent: (e) => {
    const outDiv = document.createElement('div');
    outDiv.style.width = '250px';
    outDiv.style.height = 'auto';
    const model = e.item.getModel();
    if (e.item.getType() === 'node') {
      if (model.tjType === 'note-text') {
        outDiv.innerHTML = `${model.description}`;
      }
      if (model.tjType === 'custom-node') {
        outDiv.innerHTML = `${model.label}`;
      } else {
        outDiv.innerHTML = `${model.label}`;
      }
    } else {
      const source = e.item.getSource();
      const target = e.item.getTarget();
      // eslint-disable-next-line
      if (Object.hasOwn(target, '_cfg')) {
        outDiv.innerHTML = `source: ${source.getModel().id}<br/>target: ${target.getModel().id}`;
      }
    }
    return outDiv;
  },
});

const toolbar = new G6.ToolBar({
  position: {
    x: 350,
    y: -12,
  },
  getContent: () => {
    const outDiv = document.createElement('ul');
    outDiv.class = 'tip-above';
    outDiv.innerHTML = `
    <li code="undo"><i class='bx bx-undo' color="black"></i></li>
    <li code="redo"><i class='bx bx-redo' color="black"></i></li>
    <li code="download"><i class='bx bxs-download' ></i></li>
    <li code="upload"><i class='bx bx-upload' ></i></li>
    <li code="fit"><i class='bx bx-exit-fullscreen'></i></li>`;
    return outDiv;
  },
  handleClick: (code, graph) => {
    if (code === 'undo') {
      toolbar.undo();
    } else if (code === 'redo') {
      toolbar.redo();
    } else if (code === 'download') {
      downloadData(graph.save());
    } else if (code === 'upload') {
      store.dispatch('setUploadingG6Data');
    } else if (code === 'fit') {
      graph.fitView();
    }
  },
});

/**
 * Helper functions
 */

const questContextMenu = new G6.Menu({
  getContent(evt) {
    if (evt.target && evt.target.isCanvas && evt.target.isCanvas()) {
      // header = 'Canvas ContextMenu';
    } else if (evt.item) {
      const itemType = evt.item.getType();
      // header = `${itemType} ContextMenu`;
      if (itemType === 'node') {
        return `
        <ul>
          <li code="goal">Add as a Goal</li>
          <li code="add_children">Add Children</li>
          <li code="edit_node">Edit Node</li>
          <li code="remove_node">Remove Node</li>
          <li code="reset_node">Reset Node</li>
          <li class="disabled_context" code="quest">Add as a Quest</li>
        </ul>`;
      }
    }
    return '';
  },
  handleMenuClick: async (target, item, graph) => {
    const code = target.getAttribute('code');
    if (code === 'goal') {
      await addAsGoal(item);
    } else if (code === 'quest') {
      // console.log(`${item.getModel().label} has been added as a quest`);
    } else if (code === 'add_children') {
      await addChildrens(graph, item);
    } else if (code === 'edit_node') {
      await editNodeDetail(item);
    } else if (code === 'remove_node') {
      await removeNode(graph, item);
    } else if (code === 'reset_node') {
      await resetNode(graph, item);
    }
    await saveGraphLocalAndLive(graph);
  },
  // offsetX and offsetY include the padding of the parent container
  // 需要加上父级容器的 padding-left 16 与自身偏移量 10
  offsetX: 16 + 10,
  // 需要加上父级容器的 padding-top 24 、画布兄弟元素高度、与自身偏移量 10
  offsetY: 0,
  // the types of items that allow the menu show up
  // 在哪些类型的元素上响应
  itemTypes: ['node', 'graph'],
});

const questGraphToolBar = new G6.ToolBar({
  position: {
    x: 350,
    y: -12,
  },
  getContent: () => {
    const outDiv = document.createElement('ul');
    outDiv.class = 'tip-above';
    outDiv.innerHTML = `
    <li code="undo"><i class='bx bx-undo' color="black"></i></li>
    <li code="redo"><i class='bx bx-redo' color="black"></i></li>
    <li code="download"><i class='bx bxs-download' ></i></li>
    <li code="upload"><i class='bx bx-upload' ></i></li>
    <li code="fit"><i class='bx bx-exit-fullscreen'></i></li>`;
    return outDiv;
  },
  handleClick: (code, graph) => {
    if (code === 'undo') {
      questGraphToolBar.undo();
    } else if (code === 'redo') {
      questGraphToolBar.redo();
    } else if (code === 'download') {
      downloadData(graph.save());
    } else if (code === 'upload') {
      store.dispatch('setQuestUploadingStatus');
    } else if (code === 'fit') {
      graph.fitView();
    }
  },
});

function chunkArray(array) {
  const result = [];
  for (let i = 0; i < array.length; i += 3) {
    result.push(array.slice(i, i + 3));
  }
  return result;
}

const graphContextMenu = new G6.Menu({
  getContent(evt) {
    if (evt.target && evt.target.isCanvas && evt.target.isCanvas()) {
      return `
        <ul>
          <li code="selected_nodes">Create And Link Quests(Group)</li>
          <li code="form_relationships">Form Relationships</li>
          <li code="hide_selected_nodes">Hide selected Nodes</li>
          <li code="show_all_hidden_nodes">Show All Hidden Nodes</li>
        </ul>`;
      // header = 'Canvas ContextMenu';
    } if (evt.item) {
      const itemType = evt.item.getType();
      console.log(itemType);
      // header = `${itemType} ContextMenu`;
      if (itemType === 'node') {
        return `
        <ul>
          <li code="anchor">Mark as Anchor</li>
          <li code="quest_link">Create and Link Quest</li>
        </ul>`;
      }
    }
    return '';
  },
  handleMenuClick: async (target, item, graph) => {
    const code = target.getAttribute('code');
    if (code === 'anchor') {
      await store.dispatch('gmodule/updateTextAsAnchor', item.getModel().label);
    } else if (code === 'quest_link') {
      const questResponse = await store.dispatch('quests/groupWiseQuestNode', [item.getModel().label]);
      questResponse.data.forEach(({ value }) => {
        const { nodeId, response } = value;
        const nodeItem = graph.findById(nodeId);
        nodeItem.update({
          tjType: 'custom-node',
          quest_meta_data: {
            label: response.g6_data.graph_name,
            id: response.id,
          },
          size: 30,
          style: {
            fill: '#639fff',
          },
        });
      });
      // description: {
      //   content: item.getModel()?.description ? item.getModel().description : '',
      // },
    } else if (code === 'selected_nodes') {
      const nodes = graph.findAllByState('node', 'selected');
      console.log(nodes);
      const selectedNodes = chunkArray([...nodes]);
      // eslint-disable-next-line no-restricted-syntax
      for (const currentChunk of selectedNodes) {
        // eslint-disable-next-line no-await-in-loop
        const questResponse = await store.dispatch('quests/groupWiseQuestNode', currentChunk.map((c) => c.getModel().label));
        questResponse.data.forEach(({ value }) => {
          const { nodeId, response } = value;
          const nodeItem = graph.findById(nodeId);
          nodeItem.update({
            tjType: 'custom-node',
            quest_meta_data: {
              label: response.g6_data.graph_name,
              id: response.id,
            },
            size: 30,
            style: {
              fill: '#639fff',
            },
          });
        });
      }
      store.dispatch('gmodule/saveG6ExploreData');
    } else if (code === 'form_relationships') {
      const nodes = graph.findAllByState('node', 'selected');
      store.dispatch('gmodule/formRelationshipUsingOpenAI', nodes);
      store.dispatch('gmodule/saveG6ExploreData');
    } else if (code === 'hide_selected_nodes') {
      const nodes = graph.findAllByState('node', 'selected');
      console.log(nodes);
      store.state.gmodule.hidden_node_list.forEach((currNode) => {
        graph.hideItem(currNode);
      });
      nodes.forEach((currNode) => {
        graph.hideItem(currNode);
      });
      store.dispatch('gmodule/hiddenNodesList', nodes);
      console.log(store.state.gmodule.hidden_node_list);
    } else if (code === 'show_all_hidden_nodes') {
      store.state.gmodule.hidden_node_list.forEach((currNode) => {
        graph.showItem(currNode);
      });
    }
  },
  // offsetX and offsetY include the padding of the parent container
  // 需要加上父级容器的 padding-left 16 与自身偏移量 10
  offsetX: 16 + 10,
  // 需要加上父级容器的 padding-top 24 、画布兄弟元素高度、与自身偏移量 10
  offsetY: 0,
  // the types of items that allow the menu show up
  // 在哪些类型的元素上响应
  itemTypes: ['node', 'canvas'],
});

export {
  minimap, tooltip, toolbar, questContextMenu, questGraphToolBar, graphContextMenu,
};
