/* eslint-disable no-underscore-dangle */
/**
 * Helper functions
 */

import swal from 'sweetalert';
import store from '@/store';

const questNodeTemplate = (nodeIdentifier) => ({
  id: nodeIdentifier,
  label: nodeIdentifier,
  tjType: 'openai_node',
});

const saveGraphLocalAndLive = async (graph) => {
  store.dispatch('quests/setQuestGraphData', graph.save());
  await store.dispatch('gmodule/updateGraph', {
    id: store.state.active_graph_id.quest_library,
    gdata: graph.save(),
  });
  graph.updateLayout();
};

const addAsGoal = async (item) => {
  const goal = await store.dispatch('goals/addGoal', { goal: item.getModel().label });
  // eslint-disable-next-line no-underscore-dangle
  item.update({ goal: goal._id });
  const eventPayload = {
    verb: 'ADD_AS_GOAL',
    value: {
      goalTitle: item.getModel().label,
      nodeTitle: item._cfg.id,
    },
  };
  store.dispatch('eventToEventStore', eventPayload, { root: true });
};

const addChildrens = async (graph, item) => {
  const value = await swal({
    title: 'Add Childrens',
    text: 'Enter a comma separated list of children',
    content: 'input',
    buttons: true,
  });
  if (value) {
    // console.log(value);
    let existingNodeList = '';
    // console.log(value.split(','));
    value.split(',').forEach((child) => {
      const newNode = questNodeTemplate(child.trim());
      const node = graph.findById(child.trim());

      if (!node) {
        // console.log('adding node', newNode);
        graph.addChild(newNode, item.getModel().id);
        graph.updateLayout();

        const eventPayload = {
          verb: 'ADD_CHILDREN',
          value: {
            title: newNode.label,
            parentNode: item._cfg.id,
          },
        };
        store.dispatch('eventToEventStore', eventPayload, { root: true });
      } else {
        existingNodeList += `${child.trim()}, `;
      }
    });

    if (existingNodeList.trim() !== '') {
      await swal({
        title: 'Already Existing Node',
        text: existingNodeList,
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      });
    }
  }
};

const editNodeDetail = async (item) => {
  const currentValue = item._cfg.id;
  const value = await swal({
    title: 'Edit Node',
    text: 'Enter a new name for the node',
    content: {
      element: 'input',
      attributes: {
        value: currentValue, // Set the current value as the initial value
      },
    },
    buttons: true,
  });
  if (value) {
    item.update({
      label: value,
    });
  }

  const eventPayload = {
    verb: 'EDIT_NODE',
    value: {
      title: item._cfg.id,
    },
  };
  store.dispatch('eventToEventStore', eventPayload, { root: true });
};

const removeNode = async (graph, item) => {
  const value = await swal({
    title: 'Remove Node',
    text: 'Are you sure you want to remove this node?',
    icon: 'warning',
    buttons: true,
    dangerMode: true,
  });
  if (value) {
    graph.removeChild(item.getModel().id);
  }

  const eventPayload = {
    verb: 'REMOVE_NODE',
    value: {
      title: item.getModel().id,
    },
  };
  store.dispatch('eventToEventStore', eventPayload, { root: true });
};

const resetNode = async (graph, item) => {
  const value = await swal({
    title: 'Reset Node',
    text: 'Are you sure you want to reset this node?',
    icon: 'warning',
    buttons: true,
    dangerMode: true,
  });

  if (value) {
    if (item._cfg.type === 'node') {
      const defaultStyles = {
        fill: '#FFFFFF',
      };
      graph.update(item, {
        style: defaultStyles,
        description: null,
      });
      const eventPayload = {
        verb: 'RESET_NODE',
        value: {
          title: item._cfg.id,
        },
      };
      store.dispatch('_defaultDetail');
      store.dispatch('eventToEventStore', eventPayload, { root: true });
    }
  }
};

export {
  saveGraphLocalAndLive,
  addAsGoal,
  addChildrens,
  editNodeDetail,
  removeNode,
  resetNode,
};
